import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Container from '../components/container'
import heroStyles from '../components/hero.module.css'
import styles from "../components/pageHero.module.css"
import ScrollAnimation from "react-animate-on-scroll"
import BackgroundImage from "gatsby-background-image"
import { graphql } from 'gatsby'
class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const hasImage = post?.heroImage !== null;

    return (
    <Layout>
        <Helmet title={`${post.title} | ${siteTitle}`} />

        <div className={styles.pageHero}>
  
      <div className={styles.hero}>
        {hasImage && (
          <BackgroundImage
            Tag="div"
            className={styles.heroImage}
            fluid={post.heroImage.sizes}
          >
            <div className={styles.overlay}></div>

            <div className={styles.heroDetails}>
              <ScrollAnimation animateIn="fadeIn">
                {" "}
                <h1 className={styles.headline}>{post.title}</h1>{" "}
              </ScrollAnimation>
            </div>
        </BackgroundImage>
        ) }
      </div>
 

   
  </div>



        <Container>
      
          {post.body &&
          <div
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
          }
       </Container>
        </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`

  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        sizes(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulSizes_withWebp
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
